@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MarkPro';
  font-weight: 500;
  font-display: swap;
  src: local('MarkPro Medium'),
    url('../public/fonts/MarkPro/woff2/MarkPro-Medium.woff2') format('woff2'),
    url('../public/fonts/MarkPro/woff/MarkPro-Meduim.woff') format('woff');
}

@font-face {
  font-family: 'MarkPro';
  font-weight: 800;
  font-display: swap;
  src: local('MarkPro Heavy'),
    url('../public/fonts/MarkPro/woff2/MarkPro-Heavy.woff2') format('woff2'),
    url('../public/fonts/MarkPro/woff/MarkPro-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-display: swap;
  src: local('Pretendard Black'),
    url('../public/fonts/Pretendard/woff2/Pretendard-Black.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-Black.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-display: swap;
  src: local('Pretendard ExtraBold'),
    url('../public/fonts/Pretendard/woff2/Pretendard-ExtraBold.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local('Pretendard Bold'),
    url('../public/fonts/Pretendard/woff2/Pretendard-Bold.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src: local('Pretendard SemiBold'),
    url('../public/fonts/Pretendard/woff2/Pretendard-SemiBold.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local('Pretendard Medium'),
    url('../public/fonts/Pretendard/woff2/Pretendard-Medium.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local('Pretendard Regular'),
    url('../public/fonts/Pretendard/woff2/Pretendard-Regular.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src: local('Pretendard Light'),
    url('../public/fonts/Pretendard/woff2/Pretendard-Light.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-Light.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-display: swap;
  src: local('Pretendard ExtraLight'),
    url('../public/fonts/Pretendard/woff2/Pretendard-ExtraLight.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-display: swap;
  src: local('Pretendard Thin'),
    url('../public/fonts/Pretendard/woff2/Pretendard-Thin.woff2') format('woff2'),
    url('../public/fonts/Pretendard/woff/Pretendard-Thin.woff') format('woff');
}

@font-face {
  font-family: 'MarkPro-Heavy';
  src: local('MarkHeavy'), url('../public/fonts/MarkPro/MarkHeavy.ttf') format('ttf');
}

html {
  overscroll-behavior: none;
}

button:focus {
  outline: none;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

input {
  --tw-ring-shadow: 0 0 #000 !important;
}

.slick-dots li button {
  width: 8px;
  height: 8px;
}

.slick-dots li button:before {
  color: rgba(255, 255, 255, 0.2);
}

.slick-dots li.slick-active button:before {
  color: #ffffff;
}
.slick-dots li {
  margin: 0;
}

.slick-dots {
  bottom: -35px;
}
.slick-slide {
  visibility: hidden;
}
.slick-slide.slick-active {
  visibility: visible;
}

input#default-search::-webkit-search-decoration,
input#default-search::-webkit-search-cancel-button,
input#default-search::-webkit-search-results-button,
input#default-search::-webkit-search-results-decoration {
  display: none;
}

* {
  min-height: 0;
  min-width: 0;
}

@layer components {
  .custom-section {
    @apply max-w-[800px] px-4 lg:max-w-[1280px] lg:px-6 m-auto;
  }

  .custom-section-2 {
    @apply max-w-[800px] px-0 lg:max-w-[1280px] lg:px-6 m-auto;
  }

  .custom-section-3 {
    @apply max-w-[800px] px-0 md:px-4 lg:max-w-[1280px] lg:px-6 m-auto;
  }
}

.markdown ul {
  list-style-type: revert;
}
ol.list-decimal > li > ul {
  list-style-type: disc;
}
ol.list-decimal > li > ul > li > ul {
  list-style-type: circle;
}

.claim-button {
  background: linear-gradient(135deg, #ff9900 0%, #ffc645 63.64%, #ffcc33 100%);
}
